import { Link as GatsbyLink } from "gatsby";
import {
  Header as SdsHeader,
  HeaderCollapsibleMenu,
  HeaderNav,
} from "@sikt/sds-header";
import { Link } from "@sikt/sds-core";
import * as style from "./header.module.css";

const Header = ({ currentHref }: { currentHref: string }) => {
  return (
    <SdsHeader
      className={style.header}
      topSlot={
        <nav
          className={style.topNav}
          aria-label="Navigasjon, tekniskt dokumentasjon"
        >
          <ul className={style.topNav__list} data-color-scheme="dark">
            <li>
              <GatsbyLink
                className="sds-typography-link"
                to="/"
                aria-current={currentHref === "/" && "page"}
              >
                Designsystem
              </GatsbyLink>
            </li>
            <li>
              <Link href="https://platon.sikt.no/">Platon</Link>
            </li>
            <li>
              <Link href="https://docs.feide.no/">Feide</Link>
            </li>
          </ul>
        </nav>
      }
    >
      <HeaderCollapsibleMenu aria-label="Navigasjon">
        <HeaderNav>
          <GatsbyLink
            to="/grunnleggende/"
            aria-current={currentHref === "/grunnleggende/" && "page"}
            activeClassName="sds-header__nav-link--selected"
            partiallyActive
          >
            Grunnleggende
          </GatsbyLink>
          <GatsbyLink
            to="/komponenter/"
            aria-current={currentHref === "/komponenter/" && "page"}
            activeClassName="sds-header__nav-link--selected"
            partiallyActive
          >
            Komponenter
          </GatsbyLink>
        </HeaderNav>
      </HeaderCollapsibleMenu>
      <div />
    </SdsHeader>
  );
};

export default Header;
